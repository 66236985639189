<template>
  <div class="w-100 h-100 border border-dark rounded" :style="{backgroundColor: lightsBgColor}">
    <b class="menu-title">Lights</b>
    <b-container fluid class="color-picker-container h-100">
      <b-row class="color-picker-row">
        <button type="button" @click="setRgbLed('red')" class="btn btn-danger color-picker"></button>
      </b-row>
      <b-row class="color-picker-row">
        <!-- IT IS NOT THE RIGHT COLOR BUT THERE IS A MISSMATCH SOMEWHERE ... -->
        <button type="button" @click="setRgbLed('blue')" class="btn btn-success color-picker fhg-green"></button>
      </b-row>
      <b-row class="color-picker-row">
        <button type="button" @click="setRgbLed('green')" class="btn btn-primary color-picker"></button>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "MenuMic",

  data() {
    return {
      lightsBgColor: "#8c8c8c",
      redOn: false,
      greenOn: false,
      blueOn: false,
    }
  },

  mounted() {
    const r = document.querySelector(':root')
    this.lightsBgColor = getComputedStyle(r).getPropertyValue('--tileColor')
  },   

  methods: {
    setRgbLed(color) {
      if(this.$store.state.bleConnected) {
          /* */
          const bufOn = new ArrayBuffer(1)
          const bufOff = new ArrayBuffer(1)
          let ledStateOn = new Uint8Array(bufOn)
          let ledStateOff = new Uint8Array(bufOff)
          ledStateOn[0] = 1
          ledStateOff[0] = 0

        switch(color) {
          case 'red':
            if(this.redOn === true) {
              this.redOn = false
              this.$store.state.signalingLedHandles.ledRed.writeValue(ledStateOff)
              console.log("red OFF")
            }else {
              this.redOn = true
              this.$store.state.signalingLedHandles.ledRed.writeValue(ledStateOn)
              console.log("red ON")
            }
            break
          case 'green':
            if(this.greenOn === true) {
              this.greenOn = false
              this.$store.state.signalingLedHandles.ledGreen.writeValue(ledStateOff)
              console.log("green OFF")
            }else {
              this.greenOn = true
              this.$store.state.signalingLedHandles.ledGreen.writeValue(ledStateOn)
              console.log("green ON")
            }
            break
          case 'blue':
            if(this.blueOn === true) {
              this.blueOn = false
              this.$store.state.signalingLedHandles.ledBlue.writeValue(ledStateOff)
              console.log("blue OFF")
            }else {
              this.blueOn = true
              this.$store.state.signalingLedHandles.ledBlue.writeValue(ledStateOn)
              console.log("blue ON")
            }
            break
          default:
            this.$store.state.signalingLedHandles.ledRed.writeValue(ledStateOff)
            this.$store.state.signalingLedHandles.ledGreen.writeValue(ledStateOff)
            this.$store.state.signalingLedHandles.ledBlue.writeValue(ledStateOff)
            console.log("default")
        }

        if(!this.redOn && !this.greenOn && !this.blueOn) {
          this.setLightsBgColor("#8c8c8c")
        }else if(!this.redOn && !this.greenOn && this.blueOn) {
          this.setLightsBgColor('green')
          /* *SWITCHE GREEN AND BLUE -- MISSMATCH SOMEWHERE */
        }else if(!this.redOn && this.greenOn && !this.blueOn) {
          this.setLightsBgColor('blue')
        }else if(!this.redOn && this.greenOn && this.blueOn) {
          this.setLightsBgColor('teal')
        }else if(this.redOn && !this.greenOn && !this.blueOn) {
          this.setLightsBgColor('red')
        }else if(this.redOn && !this.greenOn && this.blueOn) {
          this.setLightsBgColor('yellow')
        }else if(this.redOn && this.greenOn && !this.blueOn) {
          this.setLightsBgColor('purple')
        }else if(this.redOn && this.greenOn && this.blueOn) {
          this.setLightsBgColor('white')
        }
      }
    },

    setLightsBgColor(color) {
      this.lightsBgColor = color
    }
  },
}
</script>


<style>
.menu-title {
  font-size: 16px;
}
.bg-color {
  background-color: var(--tileColor);/*#8c8c8c;*/
}
.color-picker-container {
/*  padding-top: 42px;
  width: 75%;*/
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.color-picker-row {
  height: 25%;
  width: 100%;
  margin-bottom: 5px;
}
.color-picker {
  /*margin-right: 5px;
  margin-bottom: 5px;*/
  width: 100%;
  height: 100%;
}
.align-bottom {
  display: grid;
  align-self: end;
}
.fhg-green {
  background-color: rgb(0, 147, 116);
}
</style>