<template>          
  <router-link to="/bat-data" custom v-slot="{ navigate }" class="h-100 w-100 border border-dark rounded bg-color">
    <div role="link" @click="handler => showGraphView(handler, navigate)">
      <b-row class="w-100 text-right">
        <b class="w-100 menu-title">Battery</b>
      </b-row>
      <b-row class="no-gutters h-50">
        <div class="battery-box">
          <battery-indicator :charge="batteryCharge"/>
          <p class="text-straight">{{batteryPercent}} %</p>
        </div>
      </b-row>
      <b-row id="charging-row">
        <div id="charging-div" ref="chargingDiv">
          <b id="charging-text">CHARGING</b>
        </div>
      </b-row>
    </div>
  </router-link>
</template>

<script>
import BatteryIndicator from '../components/BatteryIndicator'

export default {
  name: "MenuBattery",
  components: {
    BatteryIndicator,
  },

  data () {
    return {
      batteryCharge: 0,
      batteryPercent: 0,
      timer: null,
    }
  },

  mounted () {
    this.timer = setInterval(this.showCharge, 1000)
  },

  methods: {
    showGraphView(handler, navigate) {
      if(this.$store.state.bleConnected) {
        return navigate(handler)
      }

      return undefined
    },

    showCharge () {
      if(this.$store.state.sBatteryAvailable) {
        this.batteryPercent = this.$store.state.batData.lvl.slice(-1)[0]
        this.batteryCharge = Math.round((this.batteryPercent/100.0) * 6)

      }else {
        this.batteryCharge = (this.batteryCharge + 1) % 6
      }
    },

    clearTimer () {
      clearInterval(this.timer)
    }
  },

  beforeDestroy () {
    this.clearTimer()
  }
}


</script>

<style scoped>
.bg-color {
  background-color: var(--tileColor);/*#8c8c8c;*/
}
.menu-title {
  font-size: 16px;
}
.battery-box {
  transform: rotate(90deg);
  position: relative;
  left: 50%;
  bottom:  70%; /*30px;*/
}

.text-straight {
  transform: rotate(-90deg);
  margin-top: 30%;
  font-size: 14px;
}

#charging-text {
  font-size: 10px;
  color: green;
}

#charging-div {
  width: 100%;
  padding-left: 25%;
  visibility: hidden;
}

#charging-row {
  height: 0;
}
</style>