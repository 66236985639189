<template>
  <div class="border border-dark rounded h-100 w-100">
    <b-row class="h-100 w-100 row-style">
      <b class="w-100 text-right menu-title">Bluetooth</b>
      <i id="ble-status" :style="{color: bleStatusColor}" class="w-100 text-right">{{bleStatusText}}</i>
      <span class="w-100 text-right">
        <bluetooth-connect-icon class="material-icons" :size="36" fillColor="#124194" @click="btConnect"/>
        <bluetooth-off-icon class="material-icons" :size="36" :style="{color: btnOffColor}" @click="btDisconnect"/>
      </span>
    </b-row>
  </div>
</template>

<script>
import BluetoothConnectIcon from 'vue-material-design-icons/BluetoothConnect.vue';
import BluetoothOffIcon from 'vue-material-design-icons/BluetoothOff.vue';

const STATE_CONNECTED = 0
const STATE_DISCONNECTED = 1
/* Device was found and connected but some services are not available */
const STATE_PARTIAL_CONNECTED = 2

export default {
  name: "MenuBluetooth",
  components: {
    BluetoothConnectIcon,
    BluetoothOffIcon,
  },

  data() {
    return {
      btnColorActive: "#124194",
      btnColorInactive: "#54575c",
      btnConnectColor: this.btnColorActive,
      btnOffColor: this.btnColorInactive,

      bleStatusText: 'Disconnected', 
      bleStatusColor: 'red',

      bleDevice: null,

      sBat: {
        uuid: '0000180f-0000-1000-8000-00805f9b34fb',
        cBatLvl: null,
        cBatLvlUuid: '00002a19-0000-1000-8000-00805f9b34fb',
        cCharging: null,
        cChargingUuid: '00002a1a-0000-1000-8000-00805f9b34fb',
        cShutdown: null,
        cShutdownUuid: '00002a1b-0000-1000-8000-00805f9b34fb'
      },      

      sMotion: {
        uuid: '00001020-702b-69b5-b243-d6094a2b0e24',
        
        cAcc: null,
        cAccUuid: '00001021-702b-69b5-b243-d6094a2b0e24',
        cAccConf: null,
        cAccConfUuid: '00001022-702b-69b5-b243-d6094a2b0e24',
        cAccCal: null,
        cAccCalUuid: '00001095-702b-69b5-b243-d6094a2b0e24',

        cGyr: null,
        cGyrUuid: '00001023-702b-69b5-b243-d6094a2b0e24',
        cGyrConf: null,
        cGyrConfUuid: '00001024-702b-69b5-b243-d6094a2b0e24',
        cGyrCal: null,
        cGyrCalUuid: '00001096-702b-69b5-b243-d6094a2b0e24',
        // No magnetometer on eval board
        cMag: null,
        cMagUuid: '00001025-702b-69b5-b243-d6094a2b0e24',
        cMagConf: null,
        cMagConfUuid: '00001026-702b-69b5-b243-d6094a2b0e24',
        cMagCal: null,
        cMagCalUuid: '00001097-702b-69b5-b243-d6094a2b0e24',
      },

      sAmbient: {
        uuid: '00001120-702b-69b5-b243-d6094a2b0e24',
        cTemp: null,
        cTempUuid: '00001133-702b-69b5-b243-d6094a2b0e24',
        cTempConf: null,
        cTempConfUuid: '00001134-702b-69b5-b243-d6094a2b0e24',
        cPress: null,
        cPressUuid: '00001135-702b-69b5-b243-d6094a2b0e24',
        cPressConf: null,
        cPressConfUuid: '00001136-702b-69b5-b243-d6094a2b0e24',
        cHum: null,
        cHumUuid: '00001137-702b-69b5-b243-d6094a2b0e24',
        cHumConf: null,
        cHumConfUuid: '00001138-702b-69b5-b243-d6094a2b0e24',
        cOpt: null,
        cOptUuid: '00001143-702b-69b5-b243-d6094a2b0e24',
        cOptConf: null,
        cOptConfUuid: '00001144-702b-69b5-b243-d6094a2b0e24',
        cPressExt: null,
        cPressExtUuid: '00001149-702b-69b5-b243-d6094a2b0e24',
        cPressExtConf: null,
        cPressExtConfUuid: '0000114a-702b-69b5-b243-d6094a2b0e24'
      },

      sSignaling: {
        uuid: '00001220-702b-69b5-b243-d6094a2b0e24',
        cLedRed: null,
        cLedRedUuid: '00001221-702b-69b5-b243-d6094a2b0e24',
        cLedGreen: null,
        cLedGreenUuid: '00001222-702b-69b5-b243-d6094a2b0e24',
        cLedBlue: null,
        cLedBlueUuid: '00001223-702b-69b5-b243-d6094a2b0e24',
      },

      sMicro: {
        uuid: '00002120-702b-69b5-b243-d6094a2b0e24',
        cFft: null,
        cFftUuid: '00002121-702b-69b5-b243-d6094a2b0e24',
        cFftConf: null,
        cFftConfUuid: '00002122-702b-69b5-b243-d6094a2b0e24',
      },

      sImpedance: {
        uuid: '00002220-702b-69b5-b243-d6094a2b0e24',
        cComplex: null,
        cComplexUuid: '00002221-702b-69b5-b243-d6094a2b0e24',
        cComplexConf: null,
        cComplexConfUuid: '00002222-702b-69b5-b243-d6094a2b0e24',
        cCalib: null,
        cCalibUuid: '00002223-702b-69b5-b243-d6094a2b0e24'
      },

      ambientSensorConfig: {
        disabled: 0b00000000,
        period10s: 0b00000001,
        period5s: 0b00100001,
        period1s: 0b01000001,
        period2Hz: 0b01100001,
        period4Hz: 0b10000001,
        period10Hz: 0b10100001,
        period20Hz: 0b11000001,
        period50Hz: 0b11100001,
      },

      accSensorConfig: {
        disabled: 0b00000000,
        period12Hz: 0b00000001,
        period25Hz: 0b01000001,
        period50Hz: 0b01100001,
        period100Hz: 0b10000001,
        period200Hz: 0b10100001,
      },

      gyrSensorConfig: {
        disabled: 0b00000000,
        period25Hz: 0b00000001,
        period50Hz: 0b00100001,
        period100Hz: 0b01000001,
        period200Hz: 0b01100001
      }
    }
  },

  async mounted() {
    if(this.$store.state.bleConnected) {

      if(this.$store.state.batData.charging == true) this.batteryShowCharging()

      if(this.$store.state.sAmbientAvailable) {
        this.sAmbient.cTemp = this.$store.state.ambHandles.temp
        this.sAmbient.cPress = this.$store.state.ambHandles.press
        this.sAmbient.cHum = this.$store.state.ambHandles.hum
        this.sAmbient.cPressExt = this.$store.state.ambHandles.pressExt

        await this.sAmbient.cTemp.startNotifications()
        await this.sAmbient.cPress.startNotifications()
        await this.sAmbient.cHum.startNotifications()
        //await this.sAmbient.cPressExt.startNotifications()

        this.guiSetBleConnected(STATE_CONNECTED);
      } else {
        /* At least one service is not available */
        this.guiSetBleConnected(STATE_PARTIAL_CONNECTED);
      }
    }else {
      this.guiSetBleConnected(STATE_DISCONNECTED);
    }
  },

  methods: {
    getBleDevice() {
      if(this.bleDevice != null) return this.bleDevice
      if(this.$store.state.bleDevice != null) return this.$store.state.bleDevice

      return null
    },

    async btConnect() {
      /* Check if we are already connected */
      if(this.$store.state.bleConnected) return

      try {
        await this.scanForDevice()
      } catch(error) {
        console.log(error)
        console.log("scanForDevice failed...")
        /* User probably canceled device chooser */
        return
      }

      /* Show spinning animation while attempting connection */
      this.$store.commit('updateBleConnecting', true)
      await this.connectAndInit()

      if(this.bleDevice.gatt.connected) {
        console.log("ble connect success")

        this.$store.commit('updateBleConState', true)
        this.$store.commit('updateBleDevice', this.bleDevice)

        this.bleDevice.addEventListener('gattserverdisconnected', this.handleDisconnect)

        /* Check for services available */
        let servicesState = this.$store.getters.getServicesState
        if(!servicesState.sAmbient || !servicesState.sMotion || !servicesState.sSignaling || !servicesState.sMic || !servicesState.sImpedance) {
          console.log("Services partialy enabled")
          console.log("Ambient: " + servicesState.sAmbient)
          console.log("Motion: " + servicesState.sMotion)
          console.log("Signaling: " + servicesState.sSignaling)
          console.log("Mic: " + servicesState.sMic)
          console.log("Impedance: " + servicesState.sImpedance)

          this.guiSetBleConnected(STATE_PARTIAL_CONNECTED);
        }else {
          this.guiSetBleConnected(STATE_CONNECTED);
        }

      } else {
        console.log("Failed to connect...")
        this.guiSetBleConnected(STATE_DISCONNECTED);
      }

      this.$store.commit('updateBleConnecting', false)
    },

    async scanForDevice() {
      let options = {
        filters: [
          {services: [this.sBat.uuid,
                      this.sAmbient.uuid, 
                      this.sMotion.uuid,
                      this.sSignaling.uuid, 
                      this.sMicro.uuid,
                      this.sImpedance.uuid
                      ]},
          {namePrefix: 'Kallisto_Sensing'},
        ]
      }

      this.bleDevice = await navigator.bluetooth.requestDevice(options)
    },

    async connectAndInit() {
      let server

      try {
        server = await this.bleDevice.gatt.connect()
      } catch(error) {
        console.log(error)
        console.log("Failed to connect to device gatt server!")
        /* No point in continuing */
        return
      }

      if(!server.connected) {
        console.log("Server is not connected, aborting!")
        return
      }

      /* Try to initialize the services. Continue on error */
      try {
        await this.batteryInit(server)
      } catch(error) {
        console.log(error)
        console.log("batteryInit failed...")
        /* Continue... */
      }

      try {
        await this.ambientInit(server)
      } catch(error) {
        console.log(error)
        console.log("ambientInit failed...")
        /* Continue... */
      }

      try {
        await this.motionInit(server)
      } catch(error) {
        console.log(error)
        console.log("motionInit failed...")
        /* Continue... */
      }

      try {
        await this.signalingInit(server)
      } catch(error) {
        console.log(error)
        console.log("signalingInit failed...")
        /* Continue... */
      }

      try {
        await this.impedanceInit(server)
      } catch(error) {
        console.log(error)
        console.log("impedanceInit failed...")
        /* Continue... */
      }

      try {
        await this.microInit(server)
      } catch(error) {
        console.log(error)
        console.log("microInit failed...")
        /* Continue... */
      }
    },

    async btDisconnect() {
      let device = this.getBleDevice()
      if(device == null) return

      await device.gatt.disconnect()
      this.guiSetBleConnected(STATE_DISCONNECTED)
      this.updateStoreOnDisconnect()

      console.log("BUTTON: ble disconnected")
    },

    async handleDisconnect() {
      this.guiSetBleConnected(STATE_DISCONNECTED)
      this.updateStoreOnDisconnect()

      console.log("HANDLER: ble disconnected")

      /* Could work but this also gets called on clicking the disconnect btn */
      //alert("Die Verbindung zum BLE Gerät wurde unterbrochen!")
    },

    updateStoreOnDisconnect() {
        this.$store.commit('updateBleConState', false)
        /* Mark all services as unavailable */
        this.$store.commit('setBatteryAvailable', false)
        this.$store.commit('setAmbientAvailable', false)
        this.$store.commit('setMotionAvailable', false)
        this.$store.commit('setSignalingAvailable', false)
        this.$store.commit('setMicAvailable', false)
        this.$store.commit('setImpedanceAvailable', false)
    },

    guiSetBleConnected(status) {
      //console.log("status = " + status)
      if(status === STATE_CONNECTED) {
        this.bleStatusText= 'Connected'
        this.bleStatusColor = 'blue'

      } else if(status === STATE_DISCONNECTED) {
        this.bleStatusText= 'Disconnected'
        this.bleStatusColor = 'red'
        this.batteryHideCharging()

      } else if(status === STATE_PARTIAL_CONNECTED) {
        this.bleStatusText= 'Connected (Errors)'
        this.bleStatusColor = 'yellow'
      }
    },

    serviceHasCharacteristic(serviceChars, charUuid) {
      for(let i = 0; i < serviceChars.length; i++) {
        if(serviceChars[i].uuid === charUuid) return true
      }

      return false
    },

    /*************************************************************************/
    /*                          SERVICE BATTERY                              */
    /*************************************************************************/    
    async batteryInit(server) {
      const service = await server.getPrimaryService(this.sBat.uuid)

      this.sBat.cBatLvl = await service.getCharacteristic(this.sBat.cBatLvlUuid)
      this.sBat.cBatLvl.addEventListener('characteristicvaluechanged', this.batteryEventHandler)

      this.sBat.cCharging = await service.getCharacteristic(this.sBat.cChargingUuid)
      this.sBat.cCharging.addEventListener('characteristicvaluechanged', this.batteryEventHandler)

      this.sBat.cShutdown = await service.getCharacteristic(this.sBat.cShutdownUuid)

      const batHandles = { lvl: this.sBat.cBatLvl, shutdownReboot: this.sBat. cShutdown }
      this.$store.commit('updateBatteryHandles', batHandles)

      /* Mark service as available */
      this.$store.commit('setBatteryAvailable', true)

      /* Enable notifications */
      await this.sBat.cBatLvl.startNotifications()
      await this.sBat.cCharging.startNotifications()
    },

    batteryEventHandler(event) {
      const targetUuid = event.target.uuid
      let rawData = event.target.value
      let data

      switch(targetUuid) {
        case (this.sBat.cBatLvlUuid):
          /* Parse bat data and add timestamp */
          data = this.parseBatteryData(rawData)
          this.$store.commit('updateBatteryData', data)
          break

        case (this.sBat.cChargingUuid):
          data = rawData.getUint8(0)
          if(data === 0) {
            //console.log("NOT CHARGING")
            this.batteryHideCharging()
            this.$store.commit('updateBatteryCharging', false)
          }else if(data === 1) {
            console.log("CHARGING")
            this.batteryShowCharging()
            this.$store.commit('updateBatteryCharging', true)
          }else {
            console.log(data)
          }
          break

        default:
          console.log('batteryEventHandler: Unknown target uuid ' + targetUuid)
          console.log(rawData)
      }
    },

    batteryShowCharging() {
      let chgDiv = document.getElementById("charging-div")
      if(chgDiv != null) chgDiv.style.visibility='visible'
    },

    batteryHideCharging() {
      let chgDiv = document.getElementById("charging-div")
      if(chgDiv != null) chgDiv.style.visibility='hidden'
    },

    parseBatteryData(rawData) {
      let lvl = rawData.getUint8(0)

      return {ts: new Date().toLocaleTimeString(), lvl: lvl}
    },

    /*************************************************************************/
    /*                          SERVICE AMBIENT                              */
    /*************************************************************************/
    async ambientInit(server) {
      /* First get ambient service */
      const service = await server.getPrimaryService(this.sAmbient.uuid)
      const chars = await service.getCharacteristics()

      /* Sensors need to be configured with uint8_t byte */
      const buf = new ArrayBuffer(1)
      let config = new Uint8Array(buf)
      config[0] = this.ambientSensorConfig.period1s // Enable and set period

      if(this.serviceHasCharacteristic(chars, this.sAmbient.cTempUuid)) {
        /* Get reference to temp value characteristic and register listener*/
        this.sAmbient.cTemp = await service.getCharacteristic(this.sAmbient.cTempUuid)
        this.sAmbient.cTemp.addEventListener('characteristicvaluechanged', this.ambientEventHandler.bind(this))

        /* Get the temp config characteristic and write the config */
        this.sAmbient.cTempConf = await service.getCharacteristic(this.sAmbient.cTempConfUuid)
        await this.sAmbient.cTempConf.writeValue(config)

        await this.sAmbient.cTemp.startNotifications()
      }

      if(this.serviceHasCharacteristic(chars, this.sAmbient.cPressUuid)) {
        this.sAmbient.cPress = await service.getCharacteristic(this.sAmbient.cPressUuid)
        this.sAmbient.cPress.addEventListener('characteristicvaluechanged', this.ambientEventHandler.bind(this))

        this.sAmbient.cPressConf = await service.getCharacteristic(this.sAmbient.cPressConfUuid)
        await this.sAmbient.cPressConf.writeValue(config)

        await this.sAmbient.cPress.startNotifications()
      }

      if(this.serviceHasCharacteristic(chars, this.sAmbient.cHumUuid)) {
        this.sAmbient.cHum = await service.getCharacteristic(this.sAmbient.cHumUuid)
        this.sAmbient.cHum.addEventListener('characteristicvaluechanged', this.ambientEventHandler.bind(this))

        this.sAmbient.cHumConf = await service.getCharacteristic(this.sAmbient.cHumConfUuid)
        await this.sAmbient.cHumConf.writeValue(config)

        await this.sAmbient.cHum.startNotifications()
      }

      if(this.serviceHasCharacteristic(chars, this.sAmbient.cOptUuid)) {
        this.sAmbient.cOpt = await service.getCharacteristic(this.sAmbient.cOptUuid)
        this.sAmbient.cOpt.addEventListener('characteristicvaluechanged', this.ambientEventHandler.bind(this))

        config[0] = this.ambientSensorConfig.period10s    //This equals 1Hz period for optical sensor
        this.sAmbient.cOptConf = await service.getCharacteristic(this.sAmbient.cOptConfUuid)
        await this.sAmbient.cOptConf.writeValue(config)

        await this.sAmbient.cOpt.startNotifications()

        config[0] = this.ambientSensorConfig.period1s     //Reset config for following sensors
      }

      /* External pressure and temperature */
      if(this.serviceHasCharacteristic(chars, this.sAmbient.cPressExtUuid)) {
        this.sAmbient.cPressExt = await service.getCharacteristic(this.sAmbient.cPressExtUuid)
        this.sAmbient.cPressExt.addEventListener('characteristicvaluechanged', this.ambientEventHandler.bind(this))

        this.sAmbient.cPressExtConf = await service.getCharacteristic(this.sAmbient.cPressExtConfUuid)
        await this.sAmbient.cPressExtConf.writeValue(config)

        await this.sAmbient.cPressExt.startNotifications()
      }

      /* Store ambient handles */
      const ambHandles = { temp: this.sAmbient.cTemp, 
                          press: this.sAmbient.cPress, 
                          hum: this.sAmbient.cHum,
                          opt: this.sAmbient.cOpt,
                          pressExt: this.sAmbient.cPressExt }
      this.$store.commit('updateAmbHandles', ambHandles)

      /* Mark service as available */
      this.$store.commit('setAmbientAvailable', true)
    },

    ambientEventHandler(event) {
      const targetUuid = event.target.uuid
      let rawData = event.target.value
      let data

      switch(targetUuid) {
        case(this.sAmbient.cTempUuid):
          data = this.parseAmbientData(rawData)
          this.$store.commit('updateAmbTemp', data.data/100.0)
          //console.log('Temperature: ' + data.data/100.0 + '°C - ' + data.ts + 'uS')
          break
        case(this.sAmbient.cPressUuid):
          data = this.parseAmbientData(rawData)
          this.$store.commit('updateAmbPress', data.data/100.0)
          //console.log('Pressure: ' + data.data/100.0 + 'mBar - ' + data.ts + 'uS')
          break
        case(this.sAmbient.cHumUuid):
          data = this.parseAmbientData(rawData)
          this.$store.commit('updateAmbHum', Math.round(data.data/10.240) / 100)
          //console.log('Humidity: ' + data.data/1024.0 + '% - ' + data.ts + 'uS')
          break
        case(this.sAmbient.cOptUuid):
          data = this.parseAmbientData(rawData)
          //console.log("Light intensity: " + data.data + " nW/cm^2")
          this.$store.commit('updateAmbOpt', data.data / 1000.0)
          break
        case(this.sAmbient.cPressExtUuid):
          data = this.parseExtAmbientData(rawData)
          //console.log(data)
          this.$store.commit('updateAmbExt', data)
          break
        default:
          console.log('ambientEventHandler: Unknown target uuid ' + targetUuid)
          console.log(rawData)

      }      
    },

    parseAmbientData(rawData) {
      let timestamp = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

      let parsedData = (rawData.getUint8(4) | (rawData.getUint8(5) << 8) 
        | (rawData.getUint8(6) << 16) | (rawData.getUint8(7) << 24))

      return {ts: timestamp, data: parsedData}
    },

    parseExtAmbientData(rawData) {
      let timestamp = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

      let temperature = (rawData.getUint8(4) | (rawData.getUint8(5) << 8) 
        | (rawData.getUint8(6) << 16) | (rawData.getUint8(7) << 24))
      temperature /= 100.0

      let pressure = (rawData.getUint8(8) | (rawData.getUint8(9) << 8) 
        | (rawData.getUint8(10) << 16) | (rawData.getUint8(11) << 24))
      pressure /= 100.0

      return {ts: timestamp, temp: temperature, press: pressure}
    },

    /*************************************************************************/
    /*                          SERVICE MOTION                               */
    /*************************************************************************/    
    async motionInit(server) {
      const service = await server.getPrimaryService(this.sMotion.uuid)

      const buf = new ArrayBuffer(1)
      let config = new Uint8Array(buf)
      config[0] = this.accSensorConfig.period25Hz // Enable and set period

      this.sMotion.cAcc = await service.getCharacteristic(this.sMotion.cAccUuid)
      this.sMotion.cAcc.addEventListener('characteristicvaluechanged', this.motionEventHandler.bind(this))

      this.sMotion.cAccConf = await service.getCharacteristic(this.sMotion.cAccConfUuid)
      await this.sMotion.cAccConf.writeValue(config)

      this.sMotion.cGyr = await service.getCharacteristic(this.sMotion.cGyrUuid)
      this.sMotion.cGyr.addEventListener('characteristicvaluechanged', this.motionEventHandler.bind(this))

      config[0] = this.gyrSensorConfig.period25Hz
      this.sMotion.cGyrConf = await service.getCharacteristic(this.sMotion.cGyrConfUuid)
      await this.sMotion.cGyrConf.writeValue(config)

      /* Store motion handles */
      const motHandles = {acc: this.sMotion.cAcc, gyr: this.sMotion.cGyr}
      this.$store.commit('updateMotHandles', motHandles)

      /* Mark service as available */
      this.$store.commit('setMotionAvailable', true)
    },

    motionEventHandler(event) {
      const targetUuid = event.target.uuid
      let rawData = event.target.value
      let data

      switch(targetUuid) {
        case(this.sMotion.cAccUuid):
          data = this.parseMotionData(rawData)
          //console.log('x: ' + data.x + ' y: ' + data.y + ' z: ' + data.z + ' ts: ' + data.ts)

          // From MST GATT spec - values between +/- 4g
          data.x = (data.x * 4.0) / 32768.0
          data.y = (data.y * 4.0) / 32768.0
          data.z = (data.z * 4.0) / 32768.0
          
          // z data is -1g when eval board lies flat on table
          this.$store.commit('updateMotDataAcc', {x: data.x, y: data.y, z: -data.z})
          break
        case(this.sMotion.cGyrUuid):
          data = this.parseMotionData(rawData)

          // From MST GATT spec - values between +/- 2000 deg/s
          data.x = (data.x * 2000.0) / 32768.0
          data.y = (data.y * 2000.0) / 32768.0
          data.z = (data.z * 2000.0) / 32768.0

          //console.log('x: ' + data.x + ' y: ' + data.y + ' z: ' + data.z);
          this.$store.commit('updateMotDataGyr', {x: data.x, y: data.y, z: data.z})
          break
        default:
          console.log('motionEventHandler: Unknown target uuid ' + targetUuid)
          console.log(rawData)
      }
    },

    parseMotionData(rawData) {
      // Build 32 bit timestamp from uint8 view
      let timestamp = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

      // Create a int16 view from buffer
      let int16View = new Int16Array(rawData.buffer)
      let x = int16View[2]
      let y = int16View[3]
      let z = int16View[4]

      return {ts: timestamp, x: x, y: y, z: z}
    },

    /*************************************************************************/
    /*                          SERVICE MICROPHONE                           */
    /*************************************************************************/        
    async microInit(server) {
      const service = await server.getPrimaryService(this.sMicro.uuid)

      this.sMicro.cFft = await service.getCharacteristic(this.sMicro.cFftUuid)
      this.sMicro.cFftConf = await service.getCharacteristic(this.sMicro.cFftConfUuid)

      /* Store mic handles */
      const micHandles = {micFft: this.sMicro.cFft, micFftConf: this.sMicro.cFftConf}
      this.$store.commit('updateMicHandles', micHandles)

      /* Mark service as available */
      this.$store.commit('setMicAvailable', true)
    },

    /*************************************************************************/
    /*                          SERVICE IMPEDANCE                            */
    /*************************************************************************/
    async impedanceInit(server) {
      const service = await server.getPrimaryService(this.sImpedance.uuid)

      this.sImpedance.cComplex = await service.getCharacteristic(this.sImpedance.cComplexUuid)
      this.sImpedance.cComplexConf = await service.getCharacteristic(this.sImpedance.cComplexConfUuid)
      this.sImpedance.cCalib = await service.getCharacteristic(this.sImpedance.cCalibUuid)

      /* Store impedance handles */
      const impHandles = {complexData: this.sImpedance.cComplex, complexConf: this.sImpedance.cComplexConf, calib: this.sImpedance.cCalib}
      this.$store.commit('updateImpHandles', impHandles)

      /* Mark service as available */
      this.$store.commit('setImpedanceAvailable', true)
    },   


    /*************************************************************************/
    /*                          SERVICE SIGNALING (LEDs)                     */
    /*************************************************************************/
    async signalingInit(server) {
      const service = await server.getPrimaryService(this.sSignaling.uuid)

      this.sSignaling.cLedRed = await service.getCharacteristic(this.sSignaling.cLedRedUuid)

      this.sSignaling.cLedGreen = await service.getCharacteristic(this.sSignaling.cLedGreenUuid)

      this.sSignaling.cLedBlue = await service.getCharacteristic(this.sSignaling.cLedBlueUuid)

      /* Store LED handles */
      const ledHandles = { ledRed: this.sSignaling.cLedRed, 
                           ledGreen: this.sSignaling.cLedGreen, 
                           ledBlue: this.sSignaling.cLedBlue }
      this.$store.commit('updateSignalingLedHandles', ledHandles)

      /* Mark service as available */
      this.$store.commit('setSignalingAvailable', true)
    },
  },  // methods:
}
</script>

<style scoped>
.row-style {
  background-color: var(--tileColor);/*#8c8c8c;*/
  margin: 0;
  padding-right: 15px;
}

.menu-title {
  font-size: 16px;
}

.material-icons {
  padding-left: 25px;
}

#ble-status {
  color: red;
  font-size: 14px;
}
</style>
