<template>
  <router-link to="/mic-data" custom v-slot="{ navigate }" class="w-100 h-100">
    <div role="link" @click="handler => showGraphView(handler, navigate)">
      <b-row class="h-100 w-100 border border-dark rounded row-style">
        <b class="w-100 menu-title">Mic</b>
        <div class="mic-pic-container">
          <div class="mic-pic" :style="micPicStyle"></div>
        </div>
      </b-row>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MenuMic",

  data() {
    return {
      micPicStyle: { backgroundImage: `url(${require('../static/microphone2.png')})` } 
    }
  },

  methods: {
    showGraphView(handler, navigate) {
      // if(this.$store.state.sMicAvailable) {
      //   console.log("Navigation to mic graph")
      //   return navigate(handler)
      // }

      // console.log("Service microphone not available!")
      // return undefined
      return navigate(handler)
    }
  }
}

</script>


<style scoped>
.row-style {
  background-color: var(--tileColor);/*#8c8c8c;*/
  margin: 0;
}

.menu-title {
  font-size: 16px;
}

.mic-pic-container {
  height: 100%;
  width:  100%;
  display:  flex;
  align-items: center;
  justify-content: center;
}

.mic-pic {
  width:  60%;
  height: 60%;
  /*background-image: url(../static/microphone2.png);*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
