<template>
  <router-link to="/sensors-data" custom v-slot="{ navigate }" class="h-100 w-100 border border-dark rounded bg-color">
    <div role="link" @click="handler => showGraphView(handler, navigate)">
      <b class="menu-title">Sensors</b>
      <b-container class="sensors-container">
        <u>Internal</u>
        <b-row>
          <b-col cols="4" class="left-col">
            <thermometer-lines-icon class="material-icons"/>
          </b-col>
          <b-col cols="4" class="mid-col">
            {{ $store.state.ambData.temp.slice(-1)[0].toFixed(2) }}
          </b-col>
          <b-col cols="4" class="right-col">
            °C
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="left-col">
            <gauge-icon class="material-icons" />
          </b-col>
          <b-col cols="4" class="mid-col">
            {{ $store.state.ambData.press.slice(-1)[0].toFixed(2) }}
          </b-col>
          <b-col cols="4" class="right-col">
            hPa
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="left-col">
            <water-percent-icon class="material-icons"/>
          </b-col>
          <b-col cols="4" class="mid-col">
            {{ $store.state.ambData.hum.slice(-1)[0].toFixed(2) }}
          </b-col>
          <b-col cols="4" class="right-col">
            %
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="left-col">
            <lightbulb-on-icon class="material-icons"/>
          </b-col>
          <b-col cols="4" class="mid-col">
            {{ $store.state.ambData.opt.slice(-1)[0].toFixed(2) }}
          </b-col>
          <b-col cols="4" class="right-col">
            mW/cm^2
          </b-col>
        </b-row>
        <u>External</u>
        <b-row>
          <b-col cols="4" class="left-col">
            <thermometer-lines-icon class="material-icons"/>
          </b-col>
          <b-col cols="4" class="mid-col">
            {{ $store.state.ambData.tempExt.slice(-1)[0].toFixed(2) }}
          </b-col>
          <b-col cols="4" class="right-col">
            °C
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="left-col">
            <gauge-icon class="material-icons" />
          </b-col>
          <b-col cols="4" class="mid-col">
            {{ $store.state.ambData.pressExt.slice(-1)[0].toFixed(2) }}
          </b-col>
          <b-col cols="4" class="right-col">
            hPa
          </b-col>
        </b-row>
      </b-container>
    </div>
  </router-link>
</template>

<script>
import WaterPercentIcon from 'vue-material-design-icons/WaterPercent.vue';
import ThermometerLinesIcon from 'vue-material-design-icons/ThermometerLines.vue';
import GaugeIcon from 'vue-material-design-icons/Gauge.vue';
import LightbulbOnIcon from 'vue-material-design-icons/LightbulbOn.vue';

export default {
  name: "MenuSensors",
  components: {
    WaterPercentIcon,
    ThermometerLinesIcon,
    GaugeIcon,
    LightbulbOnIcon,
  },


  methods: {
    showGraphView(handler, navigate) {
      // if(this.$store.state.bleConnected) {
      //   console.log("Navigating to sensor graphs")
      //   return navigate(handler)
      // }

      // console.log("Service ambient not available!")
      // return undefined
      return navigate(handler)
    }
  }
}

</script>

<style scoped>
.bg-color {
  background-color: var(--tileColor);/*#8c8c8c;*/
}
.menu-title {
  font-size: 16px;
  margin-bottom: 20px;
}
.sensors-container {
  font-size: 14px;
  margin-top: 20px;
}
.material-icons {
  align-text: left;
  margin-right: 20px;
}
.material-icons-comb {
  display: inline;
  vertical-align: top;
  padding-right: 0px;
  margin-right:  -3px;
}
.align-bottom {
  display: grid;
  align-self: end;
}
.value-col {
  text-align: center;
}
.left-col {
  padding-right: 0;
  padding-left: 15%;
}
.mid-col {
  padding: 0;
}
.right-col {
  text-align: left;
  padding-left: 0;
}
</style>
